<template>
  <div class="csn-404">
    <div class="csn-404-container">
      <img
        src="@/assets/images/404.svg"
        alt="404-image"
        class="csn-404-image"
      />
      <div class="csn-404-header">{{ t('header') }}</div>
      <span class="csn-404-text">
        Etiam porta sem malesuada magna mollis euismod. Donec ullamcorper nulla
        non metus auctor fringilla. Donec id elit non mi porta gravida at eget
        metus. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
        Nulla vitae elit libero, a pharetra augue.
      </span>
      <div class="csn-404-button-container">
        <Route
          :to="{ name: route.needHelp }"
          class="btn casino-btn casino-btn-default csn-404-help-button"
        >
          <span class="casino-icon casino-icon-chat-support"></span>
          <span>{{ t('help_btn_label') }}</span>
        </Route>
        <Route
          :to="{ name: route.home }"
          class="btn casino-btn casino-btn-pink csn-404-home-button"
        >
          <HomeIcon />
          <span>{{ t('home_btn_label') }}</span>
        </Route>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from '@/components/svg/HomeIcon'
import { RouteName, PAGE_404 } from '@/constants'

export default {
  name: PAGE_404,
  components: {
    HomeIcon,
    Route: () => import('@/components/Route'),
  },
  computed: {
    route: () => ({
      needHelp: RouteName.NEED_HELP,
      home: RouteName.HOME,
    }),
    t() {
      return this.$createComponentTranslator(PAGE_404)
    },
  },
}
</script>
